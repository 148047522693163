import React, { useState, useEffect, useCallback } from 'react';
import Calendar from "color-calendar";
import "color-calendar/dist/css/theme-glass.css";
import { Col, Divider, Row, Card, List, Avatar, message, Tag, Drawer, theme, Typography } from 'antd';
import { QuestionCircleOutlined, ClockCircleOutlined, SmileOutlined, EuroOutlined, HomeOutlined, EnvironmentOutlined, LinkOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import './index.css';
import parseDetailData from "../../detailParser";
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import marker_icon from '../../media/images/selected_marker_remap.png';
import marker_icon_svg from '../../media/images/selected_marker_remap.svg';
import marker_iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = new L.Icon({
  iconUrl: marker_icon,
  iconRetinaUrl: marker_icon_svg,
  iconAnchor: [10, 35],
  shadowUrl: marker_iconShadow,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [26, 40],
});

const EventCalendar = ({ eventsData }) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const fetchDetailData = async (eventID) => {
    try {
      const detailedData = await parseDetailData(eventID);
      setDetailData(detailedData);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching and parsing detail data:", error);
    }
  };

  const showDrawer = (eventID) => {
    fetchDetailData(eventID);
  };

  const onClose = () => {
    setOpen(false);
    setDetailData([]);
  };

  // Function to construct the icon list data
  const constructIconListData = () => {

    const iconStartDate = new Date(detailData.times[0].start).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
    const iconEndDate = new Date(detailData.times[0].end).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
    const iconStartTime = new Date(detailData.times[0].start).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    const iconEndTime = new Date(detailData.times[0].end).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

    var iconDateRange = "";
    if (iconStartDate === iconEndDate) {
      iconDateRange = iconStartDate + ", " + iconStartTime + " - " + iconEndTime + " Uhr";
    } else {
      iconDateRange = iconStartDate + ", " + iconStartTime + "Uhr - " + iconEndDate + ", " + iconEndTime + " Uhr";
    }

    const iconList = [
      {
        icon: <ClockCircleOutlined />,
        title: iconDateRange,
      },
      {
        icon: <SmileOutlined />,
        title: (detailData.target_group ? detailData.target_group : "Zielgruppe unbekannt")
      },
      {
        icon: <EuroOutlined />,
        title: (detailData.costs ? detailData.costs : "Keine Angabe zu den Kosten")
      },
      {
        icon: <HomeOutlined />,
        title: (detailData.locations[0].title ? detailData.locations[0].title : "Ort unbekannt")
      },
      {
        icon: <EnvironmentOutlined />,
        title: detailData.locations[0] ? detailData.locations[0].street + ", " + detailData.locations[0].zip + " " + detailData.locations[0].city : "Keine Adresse vorhanden",
        link: "https://www.google.com/maps/search/?api=1&query=" + detailData.locations[0].lat + "," + detailData.locations[0].long
      },
      {
        icon: <LinkOutlined />,
        title: detailData.info_url ? detailData.info_url : "Website unbekannt",
        link: detailData.info_url
      },
      {
        icon: <MailOutlined />,
        title: (detailData.contacts[0] && detailData.contacts[0].contact_persons[0]) ? detailData.contacts[0].contact_persons[0].email : "Keine E-Mail vorhanden",
        link: (detailData.contacts[0] && detailData.contacts[0].contact_persons[0] ? "mailto:" + detailData.contacts[0].contact_persons[0].email : "")
      },
      {
        icon: <PhoneOutlined />,
        title: (detailData.contacts[0] && detailData.contacts[0].contact_persons[0]) ? detailData.contacts[0].contact_persons[0].phone : "Keine Telefonnummer vorhanden",
        link: (detailData.contacts[0] && detailData.contacts[0].contact_persons[0] ? "tel:" + detailData.contacts[0].contact_persons[0].phone : "")
      }
    ];

    return iconList;
  };

  useEffect(() => {
    if (eventsData.length > 0) {
      new Calendar({
        id: "#remap-calendar",
        theme: "glass",
        startWeekday: 1,
        weekdayType: "long-upper",
        monthDisplayType: "long",
        calendarSize: "small",
        layoutModifiers: ["month-left-align"],
        customWeekdayValues: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        customMonthValues: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        eventsData: eventsData,
        dateChanged: (currentDate, events) => {
          setOpen(false);
          setSelectedEvents(events.map((event) => event.id));
          setSelectedDate(currentDate);
        },
      });
    }
  }, [eventsData]);

  return (
    //add Grid that wraps the calendar (6 parts) and creates a canvas to display a list of events (6 parts)
    <Row gutter={[16, 16]}>
      <Col className="gutter-row">
        <div id="remap-calendar"></div>
      </Col>
      <Col className="gutter-row" xs={24} md={14} lg={16} xl={16}>
        <Card title={"Veranstaltungen am " + selectedDate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' })}
          style={{
            backgroundColor: "#f9f9f9",
          }}
          bodyStyle={{ padding: 10 }}
          extra={
            <QuestionCircleOutlined
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                message.info('Hier finden Sie alle Veranstaltungen, die an diesem Tag stattfinden.');
              }}
            />
          }
        >
          <div style={{ height: 250, overflow: 'auto' }}>
            <List
              itemLayout="horizontal"
              locale={{ emptyText: "Keine Veranstaltungen an diesem Tag" }}
              dataSource={eventsData.filter((event) => selectedEvents.includes(event.id))}
              renderItem={(event) => (
                <List.Item
                  style={{ backgroundColor: "#fff", borderRadius: "20px", padding: 10, marginBottom: 5 }}
                  actions={[<a key="list-loadmore-edit" onClick={() => showDrawer(event.id)}>Mehr erfahren</a>]}>
                  <List.Item.Meta
                    avatar={<Avatar src={event.image_url || `https://api.dicebear.com/7.x/miniavs/svg?seed=${event.id}`} />}
                    title={event.name}
                    description={
                      <div>
                        <div style={{ marginBottom: '8px', color: "#000" }}>
                          <ClockCircleOutlined style={{ marginRight: '8px' }} />
                          {event.start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} - {event.end.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
                        </div>
                        {Array.isArray(event.categories) ? (
                          event.categories.map((category) => <Tag key={category} style={{ marginRight: "5px" }}>{category}</Tag>)
                        ) : (
                          event.categories.split(", ").map((category) => <Tag key={category} style={{ marginRight: "5px" }}>{category}</Tag>)
                        )}
                        <Divider style={{ margin: '8px 0' }} />
                        {event.short_description}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <Drawer
            title={detailData.title}
            size="large"
            placement="right"
            closable={true}
            onClose={onClose}
            open={open}
            getContainer={false}
            bodyStyle={{ padding: 10 }}
          >
            {open && (
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" xs={12}>
                  <Row>
                    <Col className="gutter-row" xs={6}>
                      <Avatar
                        size={{ xs: 35, sm: 40, md: 50, lg: 64, xl: 80, xxl: 100 }}
                        src={detailData.image_url || `https://api.dicebear.com/7.x/miniavs/svg?seed=${detailData.id}`}
                      />
                    </Col>
                    <Col className="gutter-row" xs={18}>
                      {Array.isArray(detailData.categories) ? (
                        detailData.categories.map((category) => <Tag key={category} style={{ marginRight: "5px" }}>{category}</Tag>)
                      ) : (
                        detailData.categories.split(", ").map((category) => <Tag key={category} style={{ marginRight: "5px" }}>{category}</Tag>)
                      )}
                    </Col>
                  </Row>
                  <Divider style={{ margin: 4 }} />
                  <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 2 }}>{detailData.title}</Typography.Title>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>{detailData.description || detailData.short_description || "Keine Beschreibung vorhanden"}</Typography.Paragraph>
                </Col>
                <Col className="gutter-row" xs={12}>
                  <List
                    size="small"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    itemLayout="horizontal"
                    dataSource={constructIconListData()} // Pass iconListData to dataSource
                    renderItem={item => (
                      <List.Item style={{ margin: 1, padding: 1 }}>
                        <List.Item.Meta
                          avatar={item.icon}
                          title={item.link ? <a href={item.link} target="_blank">{item.title}</a> : item.title}
                        />
                      </List.Item>
                    )}
                  />
                  {detailData.locations[0].lat && detailData.locations[0].long &&
                    <MapContainer style={{ height: 200, width: 300 }} center={[detailData.locations[0].lat, detailData.locations[0].long]} zoom={12} scrollWheelZoom={false}>
                      <TileLayer
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                      />
                      <Marker icon={DefaultIcon} position={[detailData.locations[0].lat, detailData.locations[0].long]} />
                    </MapContainer>
                  }
                </Col>
              </Row>
            )}
          </Drawer>
        </Card>
      </Col>
    </Row >
  );
}

export default EventCalendar;