import axios from "axios";

//fetch eventData using axios post

const parseDetailData = async (eventID) => {

    const requestBody = {
        "resource": "NLS.OfferResource",
        "action": "get",
        "params": {
          "id": eventID
        },
        "fields": {
          "title": true,
          "short_description": true,
          "description": true,
          "property_list": true,
          "categories": {
            "title": true,
            "sub_categories": true,
            "category_type": true,
          },
          "contacts": {
            "title": true,
            "contact_urls": {
              "title": true,
              "url": true
            },
            "contact_persons": {
              "name": true,
              "role": true,
              "email": true,
              "phone": true
            }
          },
          "costs": true,
          "requirements": true,
          "curriculum": true,
          "free": true,
          "child_friendly": true,
          "accessible": true,
          "climate_protection": true,
          "event_online": true,
          "event_canceled": true,
          "target_group": true,
          "info_url": true,
          "video_url": true,
          "updated_at": true,
          "image_url": true,
          "offer_type": {
            "display_name": true,
            "key": true,
            "used_attributes": true
          },
          "times": {
            "start": true,
            "end": true,
          },
          "locations": {
            "lat": true,
            "long": true,
            "title": true,
            "street": true,
            "city": true,
            "zip": true
          }
        }
      };

    try {
      const response = await axios.post(
        "https://daten.nachhaltiges-sachsen.de/api/v2",
        requestBody
      );
  
      const rawData = response.data.data;
      //format categories
        rawData.categories = rawData.categories.map((category) => category.title).join(", ");
      console.log("Raw data for event", eventID, ":", rawData);
      return rawData;
    } catch (error) {
      console.error("Error fetching event data:", error);
      return [];
    }
  };
  
  export default parseDetailData;
  