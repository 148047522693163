import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import EventCalendar from './components/EventCalendar';
import parseEventData from "./dataParser";

const App = () => {
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parsedData = await parseEventData();
        setEventsData(parsedData);
      } catch (error) {
        console.error("Error fetching and parsing event data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <EventCalendar eventsData={eventsData} />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();