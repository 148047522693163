import axios from "axios";

const requestBody = {
    "resource": "NLS.OfferResource",
    "action": "list",
    "fields": {
      "title": true,
      "short_description": true,
      "description": true,
      "categories": { "title": true },
      "locations": { "lat": true, "long": true, "zip": true, "city": true, "street": true },
      "offer_type": { "display_name": true },
      "image_url": true,
      "info_url": true,
      "times": {
        "start": true,
        "end": true
      }
    },
    "filters": {
      //"categories": [8],
      "page_size": 2000,
      "offer_type_id": 3,
      "timespan": ["2024-04-01",null]
    }
  };

//fetch eventData using axios post

const parseEventData = async () => {
    try {
      const response = await axios.post(
        "https://daten.nachhaltiges-sachsen.de/api/v2",
        requestBody
      );
  
      var rawData = response.data.data;
    console.log(rawData);
      // check if the event has a times array, a start and an end date. if not, filter it out
    
      rawData = rawData.filter((event) => event.times[0] && event.times[0].start && event.times[0].end);

      const formattedData = rawData.map((event) => ({
        id: event.id,
        name: event.title, //convert this "2024-04-14T12:00:00.000000Z" to date
        start: new Date(event.times[0].start),
        end: new Date(event.times[0].end),
        short_description: event.short_description || event.description.slice(0, 200) + "...",
        image_url: event.image_url,
        categories: event.categories.map((category) => category.title).join(", "),
      }));
      console.log(formattedData);
      return formattedData;
    } catch (error) {
      console.error("Error fetching event data:", error);
      return [];
    }
  };
  
  export default parseEventData;
  